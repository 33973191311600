.calendar-nav {
    margin: 1.5em 0;
    overflow: hidden;
    padding: .8em 0;

    li {
        list-style-type: none;
        float: left; }

    li + li {
        float: right; } }


.calendar {
    width: 100%;
    table-layout: fixed;
    margin-bottom: 55px;

    th {
        display: table-cell;
        width: percentage(7 / 100);
        font-family: $font-overlock;
        text-align: center;
        padding-bottom: 0.5em;

        abbr {
            font-weight: bold;
            letter-spacing: 1px;
            text-align: center;
            text-transform: uppercase; } }

    tr {
        border: {
            top: 1px solid $color-blue-light; }
        &:first-child {
            border-top: none; } }

    td {
        width: percentage(7 / 100);
        padding-top: 4px;
        vertical-align: top;
        text-align: center;
        border: {
            right: 1px solid $color-blue-light; }
        height: 60px;

        &:last-child {
            border-right: none; }

        &.notinmonth, &.weekend {
            background-color: $color-blue-lighter; }

        @media screen and (min-width: $screen-sm-min) {
            height: 60px; }
        @media screen and (min-width: $screen-md-min) {
            height: 100px; } }

    .today {
        background-color: $color-orange-light; }

    .vevent {
        display: block;
        padding: .5em 0; }

    .summary.urgent {
        color: $color-orange-darker; } }
