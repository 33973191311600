
@font-face {
    font-family: 'quicksand-regular';
    src: url('/resources/fonts/quicksand/quicksand-regular-webfont.eot');
    src: url('/resources/fonts/quicksand/quicksand-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/resources/fonts/quicksand/quicksand-regular-webfont.woff2') format('woff2'),
         url('/resources/fonts/quicksand/quicksand-regular-webfont.woff') format('woff'),
         url('/resources/fonts/quicksand/quicksand-regular-webfont.ttf') format('truetype'),
         url('/resources/fonts/quicksand/quicksand-regular-webfont.svg#quicksandregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'overlock-regular';
    src: url('/resources/fonts/overlock/overlock-regular-webfont.eot');
    src: url('/resources/fonts/overlock/overlock-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/resources/fonts/overlock/overlock-regular-webfont.woff2') format('woff2'),
         url('/resources/fonts/overlock/overlock-regular-webfont.woff') format('woff'),
         url('/resources/fonts/overlock/overlock-regular-webfont.ttf') format('truetype'),
         url('/resources/fonts/overlock/overlock-regular-webfont.svg#overlockregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'overlock-sc-regular';
    src: url('/resources/fonts/overlock/overlocksc-regular-webfont.eot');
    src: url('/resources/fonts/overlock/overlocksc-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/resources/fonts/overlock/overlocksc-regular-webfont.woff2') format('woff2'),
         url('/resources/fonts/overlock/overlocksc-regular-webfont.woff') format('woff'),
         url('/resources/fonts/overlock/overlocksc-regular-webfont.ttf') format('truetype'),
         url('/resources/fonts/overlock/overlocksc-regular-webfont.svg#overlock_scregular') format('svg');
    font-weight: normal;
    font-style: normal;
}