.jumbotron {
    background: url('/resources/images/backgrounds/diagmonds.png') center/15%, linear-gradient(to right, saturate($color-blue-dark, 28%), $color-badge-bg 85%);
    margin-bottom: 0;

    h1 {
        font-size: 36px;
        color: white; }

    @media screen and (min-width: $screen-sm-min) {
        background: url('/resources/images/backgrounds/diagmonds.png') center/15%, url('/resources/images/backgrounds/crest.png') right center no-repeat, linear-gradient(to right, saturate($color-blue-dark, 28%), $color-badge-bg 85%); } }

main {
    margin-top: 36px; }
