.column {
    float: left;
    margin-right: 1%;

    &:last-of-type {
        margin-right: 0; }

    > * {
        display: block;
        margin-bottom: 1rem; }

    img {
        max-width: 100%; } }

.size-1of4 {
    width: 22%; }

.size-1of3 {
    width: 31.333%; }

.size-1of2 {
    width: 49%; }

[data-columns] {
    &::before {
        content: '1 .column';

        @media screen and (min-width: $screen-sm-min) {
            content: '2 .column.size-1of2'; }

        @media screen and (min-width: $screen-md-min) {
            content: '3 .column.size-1of3'; } } }
