.template-news-feed {
    position: relative;

    .news-items-container {
        display: flex;
        flex-wrap: wrap; }

    .news-hero {
        display: flex;
        padding: 0;
        padding-top: 150px;
        height: 220px / 2;
        background-color: $jumbotron-bg;
        background: url('/resources/images/backgrounds/diagmonds.png') center/15%, linear-gradient(to right, saturate($color-blue-dark, 28%), $color-blue-darkest 85%);
        background-size: cover;

        .news-teaser {
            display: block;
            width: 100%;
            background-color: rgba($color-orange-dark, 0.6);
            color: white;
            padding: 4px 10px 6px;

            p {
                margin-bottom: 0; } } }

    .news-ctas {
        margin-top: 21px; } }
