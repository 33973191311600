.navbar {
    .navbar-brand {
        font-family: $font-overlock-sc;
        font-size: 24px; }

    .navbar-nav {
        & > li {
            border-left: 1px solid $color-orange-light;

            &:last-of-type {
                border-right: 1px solid $color-orange-light; } }

        li {
            font-family: $font-overlock;
            font-size: 18px; } } }
