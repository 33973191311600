.blog--post {
    aside {
        color: $color-text-muted;
        margin-bottom: $font-size-h1; }

    .blog--image-wrapper {
        img {
            max-width: 90%;
            margin: 21px 10%; } }

    .content {
        img {
            display: inline-block;
            width: 100%;
            height: auto;
            padding: 2px;
            margin: $padding-base-horizontal $padding-base-vertical 0 0;
            border: 1px solid $color-blue;

            @media screen and (min-width: $screen-sm-min) {
                width: 45%; } } } }
