/**
 * Colors
 */

$color-blue-lighter: #f7fcff;
$color-blue-light: #dff0fd;
$color-blue: #b0d6f4;
$color-blue-dark: #84bae4;
$color-blue-darker: #5c9aca;
$color-blue-darkest: #3c7eb0;

$color-yellow-light: #fff7df;
$color-yellow: #FFECB3;
$color-yellow-dark: #FFE28C;
$color-yellow-darker: #FFDA6A;

$color-orange-light: #FFE8DF;
$color-orange: #FFC9B3;
$color-orange-dark: #FFAD8C;
$color-orange-darker: #FF956A;
$color-orange-darkest: #FF7D4A;

$color-text: #333;
$color-text-muted: #999;
$color-text-success: $color-blue-dark;
$color-text-error: $color-orange-darkest;

$color-badge-bg: #1F232B;
$color-jumbotron-bg: #6BA6DA;

/**
 * Font stacks
 */

$font-overlock:    'overlock-regular';
$font-overlock-sc: 'overlock-sc-regular';
$font-quicksand:   'quicksand-regular';

/**
 * Image paths
 */

$bg-texture: '/resources/images/bg/texture.png';
