.template-events-feed {
    background-color: $color-blue-lighter;
    border-bottom: 1px solid $color-blue;
    margin-bottom: 50px;

    .row {
        margin: 10px 0; }

    h3 {
        color: $color-blue-darker;
        text-align: center; }

    .upcoming-event {
        display: inline-block;
        text-align: center;
        list-style-type: none; } }
