main .row {
    margin-bottom: 50px; }

.negative-margin-top {
    margin-top: -2.6em; }

#opener .row {
    margin-bottom: 21px;

    &:last-of-type {
        margin-bottom: 50px; }

    h1 {
        margin: 0; } }
