.alert {
    border: {
        top-style: solid;
        top-width: 2px;
        bottom-style: solid;
        bottom-width: 2px; }

    h2 {
        margin: 0;
        text-align: center; } }

.alert-important {
    @extend .alert;
    background-color: $color-orange-light;
    border-color: $color-orange-dark;
    color: $color-orange-darker;

    a {
        color: inherit; } }
